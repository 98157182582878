.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.icon {
  width: 4em;
}

.dayTemp {
  font-size: 2em;
}