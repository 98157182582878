@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('https://images.unsplash.com/photo-1578922290419-520d6f772401?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjF9&auto=format&fit=crop&w=1500&q=80');
  background-repeat: no-repeat;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.icon {
  width: 4em;
}

.dayTemp {
  font-size: 2em;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

* {
  margin: 0;
  padding: 0;
}

div.weather {
  margin: 8em auto;
  border-radius: 50%;
  background-color: #0000005c;
  width: 400px;
  height: 400px;
}

p {
  font-size: 1.2em;
  color: white;
}

p.city {
  font-size: 1.5em;
  padding-top: 2em;
}

p.temp {
  font-size: 9em;
}

p.date {
  font-size: 0.9em;
}

#degree {
  font-size: 0.4em;
  vertical-align: 100%;
}
